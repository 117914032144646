/** See "Message::Type" in "Perimeter Device Backend REST-Protocol" for details */
export enum MessageType {
    AbsoluteLossStaticDone = "AbsoluteLossStaticDone",
    AmbientBrightnessErrorPopupRequest = "AmbientBrightnessErrorPopupRequest",
    AmbientBrightnessErrorPopupResponse = "AmbientBrightnessErrorPopupResponse",
    ArrowStart = "ArrowStart",
    CameraImage = "CameraImage",
    ClientTokenAssignment = "ClientTokenAssignment",
    ContradictoryThresholdPopupRequest = "ContradictoryThresholdPopupRequest",
    ContradictoryThresholdPopupResponse = "ContradictoryThresholdPopupResponse",
    DeviceConnected = "DeviceConnected",
    DeviceDisconnected = "DeviceDisconnected",
    DicomWorkflowExportError = "DicomWorkflowExportError",
    DicomWorkflowExportDone = "DicomWorkflowExportDone",
    DicomWorkflowPopupRequest = "DicomWorkflowPopupRequest",
    DicomWorkflowPopupResponse = "DicomWorkflowPopupResponse",
    DocpSearchFinished = "DocpSearchFinished",
    DocpServerDetected = "DocpServerDetected",
    Done = "Done",
    FixationShiftConfirmation = "FixationShiftConfirmation",
    FixationShiftNotification = "FixationShiftNotification",
    FixationShiftPopupRequest = "FixationShiftPopupRequest",
    FixationShiftPopupResponse = "FixationShiftPopupResponse",
    InitializationDone = "InitializationDone",
    InitializationError = "InitializationError",
    InitializationStart = "InitializationStart",
    KineticConfigurePopupRequest = "KineticConfigurePopupRequest",
    KineticConfigurePopupResponse = "KineticConfigurePopupResponse",
    KineticVectorDone = "KineticVectorDone",
    KineticIsopterDone = "KineticIsopterDone",
    LampColorFilterPopupRequest = "LampColorFilterPopupRequest",
    LampColorFilterPopupResponse = "LampColorFilterPopupResponse",
    LensChangePopupRequest = "LensChangePopupRequest",
    LensChangePopupResponse = "LensChangePopupResponse",
    LightDensityClassPopupRequest = "LightDensityClassPopupRequest",
    LightDensityClassPopupResponse = "LightDensityClassPopupResponse",
    MeasurementStartError = "MeasurementStartError",
    PauseButtonNotification = "PauseButtonNotification",
    PositionUpdate = "PositionUpdate",
    ProgressUpdate = "ProgressUpdate",
    QualityUpdate = "QualityUpdate",
    ShutdownNotification = "ShutdownNotification",
    StaticStart = "StaticStart",
    SupraThresholdStaticDone = "SupraThresholdStaticDone",
    ThresholdStaticDone = "ThresholdStaticDone",
    ThresholdSuccessfullyMeasuredConfirmation = "ThresholdSuccessfullyMeasuredConfirmation",
    ThresholdSuccessfullyMeasuredNotification = "ThresholdSuccessfullyMeasuredNotification",
    UnexpectedThresholdPopupRequest = "UnexpectedThresholdPopupRequest",
    UnexpectedThresholdPopupResponse = "UnexpectedThresholdPopupResponse",
    ZeroPositionInvalidNotification = "ZeroPositionInvalidNotification",
    InitializationUpdate = "InitializationUpdate",
    ReferencePositioningStarted = "ReferencePositioningStarted",
    ReferencePositioningCompleted = "ReferencePositioningCompleted",
    StaticUpdate = "StaticUpdate",
    ReferencePositioningFailed = "ReferencePositioningFailed",
    ResponseButtonPressed = "ResponseButtonPressed"
}